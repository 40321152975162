<template>
  <div>
    <div :class="{'opacity-30': !getSaveLink.is_tags_enabled}" v-if="toggle">
      <p class="text !text-[#202324] font-bold">Add Tags</p>

      <div class=" mt-3 tag_input mb-3">
        <div class="tag_input_inner" v-on-clickaway="closeTag">
          <FloatingLabelInput
            id="tags"
            v-model="getLinks.linkTag"
            type="text"
            label="Tags"
            :disabled="!getSaveLink.is_tags_enabled"
            @focus="addLinkTag($event,getLinks.linkTag)"
            @blur="blurFocusTag()"
            @keyup="addLinkTag($event,getLinks.linkTag)"
            customClass="h-[3.5rem] bg-[#F4F6FA]"
          >
          </FloatingLabelInput>


          <ul class="tag_list_dd mt-[0.5rem] !rounded-[0.5rem] !border-[0.013rem] grid_box_shadow !border-[#ECEEF5] !bg-white py-[0.5rem]" v-if="getLinks.linkTag.length > 0 && (getSaveLink && getSaveLink.tags && checkTagNotExist(getLinks.linkTag,getSaveLink.tags))">
            <template v-if="getLinks.suggestedTags && getLinks.suggestedTags.length > 0">
              <li class="old_item hover:!bg-[#F4F6FA] py-[1rem]" v-for="(tag,index) in getLinks.suggestedTags">
                <span class="font-poppins !px-[1rem] py-0 text-[0.875rem] text-[#3C4549]" @click.prevent="addTagSuggestion(tag._id,index)">{{ getTagName(tag._id) }}</span>
              </li>
            </template>
            <li v-else class="new_item hover:!bg-[#F4F6FA] py-[1rem]">
                  <span class="font-poppins !px-[1rem] py-0 text-[0.875rem] text-[#3C4549]" @click.prevent="addLinkTag($event,getLinks.linkTag,true)">Add new tag "{{getLinks.linkTag }}"</span>
            </li>
          </ul>
          <ul class="tag_list_dd mt-[0.5rem] !rounded-[0.5rem] !border-[0.013rem] grid_box_shadow !border-[#ECEEF5] !bg-white py-[0.5rem]" v-else-if="getLinks.linkTag.length === 0 && getLinks.focus=== true && getLinks.suggestedTags && getLinks.suggestedTags.length > 0">
            <li class="old_item hover:!bg-[#F4F6FA] py-[1rem]" v-for="(tag,index) in getLinks.suggestedTags">
              <span class="font-poppins !px-[1rem] py-0 text-[0.875rem] text-[#3C4549]" @click.prevent="addTagSuggestion(tag._id,index)">{{ getTagName(tag._id) }}</span>
            </li>
          </ul>
        </div>
        <div class="tag-item-list mt-[0.5rem] mb-2" v-if="getSaveLink.tags.length > 0">
          <ul>
            <li class="!bg-[#F4F6FA] !mr-[0.4rem] !shadow-none" v-for="tag in getSaveLink.tags" v-if="getTagName(tag) !== '' && getTagName(tag) && getTagName(tag).length > 0">
              <span class="text">{{ getTagName(tag) }}</span>
              <span @click.prevent="removeTag(tag)" class="cross_icon"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: ['toggle'],
  components: {
    FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
  },
  computed: {
    ...mapGetters(['getLinks', 'getSaveLink'])
  },
  methods: {
    closeTag () {
      this.getLinks.focus = false
    },
    validateTags() {
      return this.getSaveLink.tags && this.getSaveLink.tags.length > 0
    }
  }
}
</script>
